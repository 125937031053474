import { styled, css } from "styled-components";
import { useNavigate, useOutletContext } from "react-router-dom";

import { useState, useRef, useCallback, useEffect } from "react";

import Icon from "../../../global/Icon";

import NewsImg1 from "../../../images/news/news_1.jpg";
import NewsImg2 from "../../../images/news/news_2.jpg";
import NewsImg3 from "../../../images/news/news_3.jpg";

import BgLine1 from "../../../images/news/lines_1.png";
import BgLine2 from "../../../images/news/lines_2.png";

import Button from "../../../global/Button";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";

import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../utils/rwd";

import { useGetArticleListQuery } from "../../../services/articleApi";

import parse from "html-react-parser";

import { Element } from "react-scroll";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled(Element)`
  padding-top: 150px;
  padding-bottom: 127px;
  position: relative;
  overflow: hidden;

  ${RWD_SM(css`
    padding: 50px 0;
    width: 77.77%;
    overflow: visible;
  `)};
`;

const TitleWrapper = styled.div`
  padding-bottom: 50px;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.colors.titleBrown};
  padding-bottom: 20px;
  font-family: ${({ theme }) => theme.fonts[2]};
  font-weight: bold;
  font-size: clamp(24px, 2.0833vw, 40px);
`;

const SubTitle = styled.div`
  color: ${({ theme }) => theme.colors.mainBrown};
  font-size: clamp(14px, 1.25vw, 24px);
`;

const SlideWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 100px;
  position: relative;

  ${RWD_SM(css`
    padding-bottom: 50px;
  `)};
`;

const SlideContainer = styled.div`
  /* width: auto; */
  width: 71%;

  ${RWD_SM(css`
    width: 100%;
  `)};
`;

const Slide = styled.div`
  border-radius: 24px;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.white};
  filter: drop-shadow(3px 3px 10px rgba(61, 42, 13, 0.05));
`;

const SlideImg = styled.div`
  display: flex;
  overflow: hidden;
  height: clamp(160px, 12.5vw, 240px);

  img {
    transition: all 0.3s ease-in-out;
    object-fit: cover;
    object-position: center;

    ${Slide}:hover & {
      transform: scale(1.2);
    }
  }
`;

const SlideContentWrapper = styled.div`
  padding: clamp(18px, 1.5625vw, 30px);
  text-align: left;
  height: calc(100% - clamp(160px, 12.5vw, 240px));
  display: flex;
  flex-direction: column;
  align-self: stretch;
  justify-content: space-between;
`;

const SlideTitle = styled.div`
  font-size: clamp(16px, 1.25vw, 24px);
  text-align: left;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.titleBrown};
`;

const SlideContent = styled.div`
  font-size: clamp(12px, 0.9375vw, 18px);
  padding: 15px 0;
  color: ${({ theme }) => theme.colors.mainBrown};
`;

const SlideTagWrapper = styled.div`
  display: flex;
`;

const SlideTag = styled.div`
  font-size: clamp(10px, 0.833vw, 16px);
  border-radius: 100px;
  margin-right: 10px;
  text-align: center;
  background: ${({ theme }) => theme.colors.lightBrown};
  &:last-child {
    margin-right: 0;
  }
`;

const SlideTagText = styled.div`
  padding: 5px 8px;
  color: ${({ theme }) => theme.colors.tagBrown};
`;

const BgLineWrapperOne = styled.div`
  position: absolute;
  bottom: 10%;
  right: 55%;
  transform: rotate(6.82deg);
  width: 45.36vw;

  ${RWD_SM(css`
    width: 74.72vw;
    transform: rotate(-135.65deg);
    bottom: 5;
  `)};
`;

const BgLineWrapperTwo = styled.div`
  position: absolute;
  top: 19%;
  left: 40%;
  width: 64.27vw;
  display: flex;

  ${RWD_SM(css`
    height: 77.22vw;
    top: 40%;
    transform: rotate(23.34deg);
  `)};
`;

const NavigationButtonLeft = styled.button`
  width: 3.33vw;
  height: 3.33vw;
  position: relative;
  z-index: 5;
  background: ${({ theme }) => theme.colors.gray};
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transform: scaleX(-1);
  cursor: pointer;
  margin-right: 5vw;
  border: none;

  svg {
    width: 1.25vw;
    height: 1.25vw;
  }

  & path {
    stroke: ${({ $isEnd }) => ($isEnd ? "#BBAF95" : "")};
  }

  ${RWD_SM(css`
    display: none;
  `)};
`;

const NavigationButtonRight = styled.button`
  width: 3.33vw;
  height: 3.33vw;
  position: relative;
  background: ${({ theme }) => theme.colors.gray};
  z-index: 5;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 5vw;
  border: none;

  svg {
    width: 1.25vw;
    height: 1.25vw;
  }

  & path {
    stroke: ${({ $isEnd }) => ($isEnd ? "#BBAF95" : "")};
  }

  ${RWD_SM(css`
    display: none;
  `)};
`;

const ButtonWrapper = styled.div`
  position: relative;
  z-index: 2;
`;

const PaginationItem = styled.div`
  height: 10px;
  width: 100%;
  position: absolute;
  bottom: 10px;
  display: none;

  ${RWD_SM(css`
    display: block;
  `)};
`;

const News = () => {
  const navigate = useNavigate();
  const windowSize = useOutletContext();

  const [swiperView, setSwiperView] = useState(3);

  useEffect(() => {
    if (windowSize[0] <= "1024") {
      setSwiperView(2);
      if (windowSize[0] <= "430") {
        setSwiperView(1);
      }
    } else {
      setSwiperView(3);
    }
  }, [windowSize]);

  const sliderRef = useRef(null);

  const [isEnd, setIsEnd] = useState(false);
  const [isBeginning, setIsBeginning] = useState(true);

  const [slideIndex, setSlideIndex] = useState(0);

  useEffect(() => {
    if (sliderRef.current?.swiper.isBeginning) {
      setIsBeginning(true);
    } else {
      setIsBeginning(false);
    }

    if (sliderRef.current?.swiper.isEnd) {
      setIsEnd(true);
    } else {
      setIsEnd(false);
    }
  }, [slideIndex]);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    setSlideIndex(sliderRef.current?.swiper.realIndex);
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    setSlideIndex(sliderRef.current?.swiper.realIndex);
    sliderRef.current.swiper.slideNext();
  }, []);

  const [newsList, setNewsList] = useState([
    {
      id: 1,
      img: NewsImg1,
      link: "",
      title: "如何排毒一身輕？維他命C飽和法的不二之選！",
      content:
        "如何確定你的身體對維生素C這種關鍵營養素的需求？ADX 發現維生素C飽和法就是一種簡單而安全的方式。",
      tag: ["營養師教路", "小知識"],
    },
    {
      id: 2,
      img: NewsImg2,
      link: "",
      title: "如何調理胃酸？（上）",
      content: "胃酸在消化過程發揮至關重要的作用。",
      tag: ["營養師教路"],
    },
    {
      id: 3,
      img: NewsImg3,
      link: "",
      title: "這三個瑜伽動作，堅持練習，保證越活越年輕！",
      content: "有人說，運動改變顏值，瑜伽改變氣質，還不快來學習！",
      tag: ["營養師教路", "小知識", "瑜伽教路"],
    },
    {
      id: 4,
      img: NewsImg1,
      link: "",
      title: "如何排毒一身輕？維他命C飽和法的不二之選！",
      content:
        "如何確定你的身體對維生素C這種關鍵營養素的需求？ADX 發現維生素C飽和法就是一種簡單而安全的方式。",
      tag: ["營養師教路", "小知識"],
    },
    {
      id: 5,
      img: NewsImg2,
      link: "",
      title: "如何調理胃酸？（上）",
      content: "胃酸在消化過程發揮至關重要的作用。。",
      tag: ["營養師教路"],
    },
    {
      id: 6,
      img: NewsImg3,
      link: "",
      title: "這三個瑜伽動作，堅持練習，保證越活越年輕！",
      content: "有人說，運動改變顏值，瑜伽改變氣質，還不快來學習！",
      tag: ["營養師教路", "小知識", "瑜伽教路"],
    },
  ]);

  const { data, isSuccess, isError, error } = useGetArticleListQuery({
    page: 1,
  });

  const [articleList, setArticleList] = useState([]);

  // const render = () => {
  //   articleList.map((data) => console.log(data));
  // };

  const pagination = {
    el: ".swiper-pagination",
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + "</span>";
    },
  };

  const imgList = [NewsImg1, NewsImg2, NewsImg3];

  useEffect(() => {
    if (isSuccess) {
      setArticleList(data.previewArticleList.slice(0, 5));
    }

    if (isError) {
      console.log(error.data.Message);
    }
  }, [data, isError]);

  const handleLink = (id) => {
    navigate(`/news/${id}`);
  };

  return (
    <Wrapper>
      <Container name="news">
        <TitleWrapper>
          <Title>ADX 新聞</Title>
          <SubTitle>
            ADX Life 即時提供客戶功能醫學、身體保健及心靈照護的最新資訊
          </SubTitle>
        </TitleWrapper>

        <SlideWrapper>
          <NavigationButtonLeft onClick={handlePrev} $isEnd={isBeginning}>
            <Icon.Slide_arrow />
          </NavigationButtonLeft>
          <SlideContainer>
            <Swiper
              spaceBetween={20}
              slidesPerView={swiperView}
              ref={sliderRef}
              pagination={pagination}
              modules={[Navigation, Pagination]}
            >
              {articleList.map((data, index) => (
                <SwiperSlide key={data.articleId}>
                  <Slide onClick={() => handleLink(data.articleId)}>
                    <SlideImg>
                      {data?.coverImg ? (
                        parse(data?.coverImg)
                      ) : (
                        <img src="https://picsum.photos/id/292/2048/1365" />
                      )}
                    </SlideImg>
                    <SlideContentWrapper>
                      <SlideTitle>
                        {data.articleTitle.replace(/<[^>]+>/g, "")}
                      </SlideTitle>
                      <SlideContent>
                        {`${data.partContent.replace(/<[^>]+>/g, "")}...`}
                      </SlideContent>
                      <SlideTagWrapper>
                        {data?.tagList?.map((tag, index) => (
                          <SlideTag key={tag.tagId}>
                            <SlideTagText>{tag.tagName}</SlideTagText>
                          </SlideTag>
                        ))}
                      </SlideTagWrapper>
                    </SlideContentWrapper>
                  </Slide>
                </SwiperSlide>
              ))}
            </Swiper>
          </SlideContainer>
          <NavigationButtonRight onClick={handleNext} $isEnd={isEnd}>
            <Icon.Slide_arrow />
          </NavigationButtonRight>
          <PaginationItem>
            <div className="swiper-pagination"></div>
          </PaginationItem>
        </SlideWrapper>
        {/* <ButtonWrapper>
          <Button text={"See More"} />
        </ButtonWrapper> */}

        <BgLineWrapperOne>
          <img src={BgLine1} />
        </BgLineWrapperOne>
        <BgLineWrapperTwo>
          <img src={BgLine2} />
        </BgLineWrapperTwo>
      </Container>
    </Wrapper>
  );
};

export default News;
