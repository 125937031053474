import { styled, css } from "styled-components";
import NewsImg from "../../../images/newspage/news1.png";
import { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";

import Button from "../../../global/Button";

import PaginationButton from "../../../global/PaginationButton";

import YouTubeIframe from "../components/YouTubeIframe";

import { dateTimeFormat } from "../../../utils";

import {
  RWD_XL,
  RWD_LG,
  RWD_LL,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../utils/rwd";
import parse from "html-react-parser";

import {
  useGetArticleInfoQuery,
  useGetAllArticleListQuery,
} from "../../../services/articleApi";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background: ${({ theme }) => theme.colors.gray}; */
`;

const Container = styled.div`
  width: clamp(300px, 62.5vw, 1200px);
  padding-top: 80px;
  padding-bottom: 200px;

  // 1200px
  text-align: left;

  ${RWD_LL(css`
    width: 83.22vw;
  `)};

  ${RWD_MD(css`
    padding-top: 30px;
    padding-bottom: 60px;
  `)};
`;

const TopImgWrapper = styled.div`
  width: 100%;
  height: clamp(300px, 27.0833vw, 520px);
  border-radius: 10px;
  overflow: hidden;
  /* border: 1px solid ${({ theme }) => theme.colors.copyRight}; */
  display: inline-flex;
  background: rgba(187, 175, 149, 0.1);

  img {
    object-fit: contain;

    /* object-position: center; */
  }

  ${RWD_SM(css`
    height: 51.667vw;

    img {
      height: 51.667vw;
      object-fit: cover;
    }
  `)};
`;

const TitleWrapper = styled.div`
  padding-top: 70px;
  padding-bottom: 20px;

  ${RWD_SM(css`
    padding-top: 30px;
    padding-bottom: 15px;
  `)};
`;

const Title = styled.div`
  font-size: 48px;
  font-size: clamp(24px, 2.5vw, 48px);
  font-weight: bold;
  color: ${({ theme }) => theme.colors.titleBrown};
`;

const HashTagWrapper = styled.div`
  padding-bottom: 20px;

  ${RWD_SM(css`
    padding-bottom: 5px;
  `)};
`;

const HashTag = styled.div`
  font-size: 30px;
  font-size: clamp(14px, 1.5625vw, 30px);
  color: ${({ theme }) => theme.colors.blue};
`;

const TimeWrapper = styled.div``;

const Time = styled.div`
  font-size: 24px;
  font-size: clamp(12px, 1.25vw, 24px);
  color: ${({ theme }) => theme.colors.mainBrown};
`;

const ArticleWrapper = styled.div`
  padding-top: 50px;
  padding-bottom: 80px;
  text-align: justify;
  line-height: 2.5;

  ${RWD_SM(css`
    padding-top: 20px;
    padding-bottom: 40px;
  `)};
`;

const ArticleContainer = styled.div``;

const Article = styled.p`
  font-size: 28px;
  font-size: clamp(16px, 1.45833vw, 28px);
  color: ${({ theme }) => theme.colors.titleBrown};
`;

const VideoWrapper = styled.div`
  width: clamp(300px, 37.5vw, 720px);
  margin: 0 auto;
  padding: 100px 0;

  ${RWD_SM(css`
    width: 100%;
    padding: 30px 0;
  `)};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PageButton = styled.div`
  & Button:first-child {
    margin-right: 30px;
  }

  ${RWD_SM(css`
    width: 100%;
    display: flex;
    justify-content: space-between;

    & Button:first-child {
      margin-right: 0;
    }
  `)};
`;

const ShareButton = styled.div`
  ${RWD_SM(css`
    display: none;
  `)};
`;

const NewsPage = () => {
  const navigate = useNavigate();
  const [player, setPlayer] = useState(null);
  const [articleData, setArticleData] = useState([]);
  const [articleContent, setArticleContent] = useState("");
  const [articleTitle, setArticleTitle] = useState("");
  const [coverImg, setCoverImg] = useState("");
  const [allArticleList, setAllArticleList] = useState([]);

  const { newsId } = useParams();

  console.log();

  const { data, isSuccess, isError, error } = useGetArticleInfoQuery({
    articleId: newsId,
  });

  const {
    data: allArticleListData,
    isSuccess: allArticleListIsSuccess,
    isError: allArticleListIsError,
    error: allArticleListError,
  } = useGetAllArticleListQuery();

  useEffect(() => {
    if (isSuccess) {
      console.log(new Date(data.createdDate));
      setArticleData([
        {
          articleId: data.articleId,
          articleTitle: data.articleTitle,
          articleContent: data.articleContent,
          coverImg: data.coverImg,
          createdDate: dateTimeFormat(data.createdDate),
          tagList: data.tagList,
        },
      ]);
    }
  }, [data, isError]);

  useEffect(() => {
    if (allArticleListIsSuccess) {
      console.log(allArticleListData.articleIDList);
      setAllArticleList(allArticleListData.articleIDList);
    }
  }, [allArticleListData, allArticleListIsError]);

  const onPlayerReady = (event) => {
    event.target.playVideo();
    event.target.mute();
  };

  const [articleList, setArticleList] = useState([
    {
      section: 1,
      text: "如何確定你的身體對維生素C這種關鍵營養素的需求？ADX 發現維生素C飽和法就是一種簡單而安全的方式。",
    },
    {
      section: 1,
      text: "維生素C飽和法只要試過第一次後，你就會知道它會對你產生什麼樣的影響，這樣日後你可以根據生活中的安排做出相應的規劃。在這個過程中，你也可以瞭解到你個人的身體情況對維生素C的劑量需求，從而有助於你在最大程度上獲得維生素C的益處。",
    },
    {
      section: 1,
      text: "舉個例子，比如說你攝取到10克維生素C的時候開始腹瀉（儘管真實情況下大多數人需要更多的量才能達至這一效果），那麼平時你就應該每天服用一半的量也就是5克。由於維生素C很容易被身體快速耗盡，所以最好分次服用，每天服用幾次，而不是每天服用一次。例如早上吃兩克，下午吃一克，晚上再吃兩克。",
    },
    {
      section: 1,
      text: "嘗試過維生素C飽和法之後，你可能會驚訝的發現你實際上需要的維生素C的量遠遠大於你以焉的，或者說你由通常接觸到的知識途徑中瞭解到的。如果你想用它作為一種天然的身體排毒劑，或者你想令免疫系統回復到良好的工作狀態，建議每週進行一次維生素C飽和法，持續一到兩個月。如果你本身就對自己的健康程度比較滿意，可以選擇每月進行一次，就像我們會定期清理家居一樣。",
    },
    {
      section: 2,
      text: "第一步：購買優質的維生素C（醫學上也稱為抗壞血酸） 市面上很多品牌的維生素C產品都是由轉基因玉米提取物製成的。想知道怎麼選擇符合GMO的維生素C補充品？請參閱另一篇文章：尋找最佳維生素C。",
    },
    {
      section: 2,
      text: "第二步：一杯維生素C飲品將3000毫克（請閱讀你所選擇的維生泰C產品營養標簽確定分量）與幾盎司的水或鮮榨果汁混合，充分溶解均勻後喝下去。新鮮榨取並連渣的果汁其實是不錯的東西，它當中還會包含植物營養素。",
    },
    {
      section: 2,
      text: "第三步：持續飲用維生素C飲品 每15分鐘重複一次第二步，直至出現水樣腹瀉 （不僅是腸道排出氣體）。說到這裏你會不會覺得擔心 ？但其實是沒有問題的，一旦停止飲用，腹瀉很快就會消失。當然，還是不建議你選擇在行山、晚宴、重要商務會議的同一天進行第一次嘗試。",
    },
  ]);

  const handleNext = () => {
    const page =
      allArticleList[allArticleList.findIndex((data) => data == newsId) + 1];
    navigate(`/news/${page}`);
  };

  const handlePrev = () => {
    // if (newsId == 1) return;
    const page =
      allArticleList[allArticleList.findIndex((data) => data == newsId) - 1];
    navigate(`/news/${page}`);
  };

  return (
    <Wrapper>
      {articleData.length > 0 && (
        <Container>
          <TopImgWrapper>
            {articleData?.[0]?.coverImg ? (
              parse(articleData?.[0]?.coverImg)
            ) : (
              <img src="https://picsum.photos/id/292/2048/1365" />
            )}
          </TopImgWrapper>
          <TitleWrapper>
            <Title> {parse(articleData?.[0]?.articleTitle)}</Title>
          </TitleWrapper>
          {articleData?.[0].tagList.length > 0 && (
            <HashTagWrapper>
              {articleData?.[0].tagList.map((data) => (
                <HashTag>#{data.tagName}</HashTag>
              ))}
            </HashTagWrapper>
          )}

          <TimeWrapper>
            <Time>{articleData?.[0]?.createdDate}</Time>
          </TimeWrapper>
          <ArticleWrapper>
            {parse(articleData?.[0]?.articleContent)}
            {/* {articleList
            .filter((data) => data.section == 1)
            .map((data) => (
              <ArticleContainer>
                <Article>{data.text}</Article>
              </ArticleContainer>
            ))}

          <VideoWrapper>
            <YouTubeIframe
              v={"h2ku-OExb4M"}
              t={0}
              playerid="player"
              player={player}
              setPlayer={(pl) => setPlayer(pl)}
              onPlayerReady={onPlayerReady}
            />
          </VideoWrapper>

          {articleList
            .filter((data) => data.section == 2)
            .map((data) => (
              <ArticleContainer>
                <Article>{data.text}</Article>
              </ArticleContainer>
            ))} */}
          </ArticleWrapper>

          <ButtonWrapper>
            <PageButton>
              {allArticleList.findIndex((data) => data == newsId) != 0 && (
                <PaginationButton text={"PREV"} fun={handlePrev} />
              )}

              {allArticleList.findIndex((data) => data == newsId) <
                allArticleList.length - 1 && (
                <PaginationButton text={"NEXT"} fun={handleNext} />
              )}
            </PageButton>
            {/* <ShareButton>
              <Button text={"SHARE"} />
            </ShareButton> */}
          </ButtonWrapper>
        </Container>
      )}
    </Wrapper>
  );
};

export default NewsPage;
