import "./App.css";

import { Provider } from "react-redux";
import store from "./store";
import { Route, Routes } from "react-router-dom";
import { Suspense, lazy } from "react";
import Loading from "./global/Loading";

// scrollToTop
import ScrollToTop from "./utils/ScrollToTop";

// frontend
import Layout from "./pages/Frontend/Layout";
import HomePage from "./pages/Frontend/HomePage";
import NewsPage from "./pages/Frontend/NewsPage";
import FaqsPage from "./pages/Frontend/FaqsPage";
import AppointmentPage from "./pages/Frontend/AppointmentPage";
import ServicesPgae from "./pages/Frontend/ServicesPgae";
import CompanyProfilePage from "./pages/Frontend/AboutPage/CompanyProfilePage";
import FunctionalDiagnosticNutritionPage from "./pages/Frontend/AboutPage/FunctionalDiagnosticNutritionPage";
import TeamPage from "./pages/Frontend/AboutPage/TeamPage";

import FunctionalMedicinePage from "./pages/Frontend/FunctionalMedicinePage";

// backend
import LoginPage from "./pages/Backend/LoginPage";
import BackendLayout from "./pages/Backend/BackendLayout";
import EditorPage from "./pages/Backend/EditorPage";
import ArticlePage from "./pages/Backend/ArticlePage/Index";
import AppointmentSchedulePage from "./pages/Backend/AppointmentSchedulePage";
import DoctorInfoPage from "./pages/Backend/DoctorInfoPage";
import RemoteSchedulePage from "./pages/Backend/RemoteSchedulePage";

import Protected from "./routes/Protected";
// const AppointmentSchedulePage = lazy(() => {
//   import("./pages/Backend/AppointmentSchedulePage");
// });

function App() {
  return (
    // <GoogleReCaptchaProvider
    //   reCaptchaKey="6LfK6lspAAAAAOsCczhRN1evoQdyAGdAKuB25tEL"
    //   scriptProps={{
    //     async: false, // optional, default to false,
    //     defer: false, // optional, default to false
    //     appendTo: "head", // optional, default to "head", can be "head" or "body",
    //     nonce: undefined, // optional, default undefined
    //   }}
    // >

    // </GoogleReCaptchaProvider>

    <Provider store={store}>
      <div className="App">
        <ScrollToTop />
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<HomePage />} />
              <Route path="news/:newsId" element={<NewsPage />} />
              <Route path="faqs" element={<FaqsPage />} />
              <Route path="appointment" element={<AppointmentPage />} />

              <Route path="services/:servicesType" element={<ServicesPgae />} />
              <Route path="about">
                <Route
                  path="company_profile"
                  element={<CompanyProfilePage />}
                />
                <Route
                  path="functional_medicine"
                  element={<FunctionalMedicinePage />}
                />
                <Route
                  path="functional_diagnostic_nutrition"
                  element={<FunctionalDiagnosticNutritionPage />}
                />
                <Route path="team" element={<TeamPage />} />
              </Route>
            </Route>

            <Route path="/login" element={<LoginPage />} />
            <Route
              path="/backend"
              element={
                <Protected>
                  <BackendLayout />
                </Protected>
              }
            >
              {/* <Route path="edit/:articleInfo" element={<EditorPage />} />
            <Route path="article" element={<ArticlePage />} /> */}
              <Route
                path="appointmentSchedule"
                element={
                  <Protected>
                    <AppointmentSchedulePage />
                  </Protected>
                }
              />
              <Route
                path="doctorInfo/:doctorId"
                element={
                  <Protected>
                    <DoctorInfoPage />
                  </Protected>
                }
              />
              <Route
                path="remoteSchedule"
                element={
                  <Protected>
                    <RemoteSchedulePage />
                  </Protected>
                }
              />
            </Route>
          </Routes>
        </Suspense>
      </div>
    </Provider>
  );
}

export default App;
